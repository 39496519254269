<template>
  <d3-network :net-nodes="nodes" :net-links="links" :options="options"/>
</template>
<script>
import D3Network from "vue-d3-network";

export default {
  name: "networkGraph",
  components: { D3Network },

  data: () => ({
    nodes: [
      { id: 1 },
      { id: 2 },
      { id: 3 },
      { id: 4 },
      { id: 5 },
      { id: 6 },
      { id: 7 },
      { id: 8 },
      { id: 9 },
      { id: 10 },
      { id: 11 },
      { id: 12 },
      { id: 13 },
      { id: 14 },
      { id: 15 },
    ],
    links: [
      { sid: 1, tid: 2 },
      { sid: 2, tid: 8 },
      { sid: 3, tid: 4 },
      { sid: 4, tid: 5 },
      { sid: 5, tid: 6 },
      { sid: 7, tid: 8 },
      { sid: 5, tid: 8 },
      { sid: 3, tid: 8 },
      { sid: 7, tid: 10 },
      { sid: 3, tid: 11 },
      { sid: 12, tid: 3 },
      { sid: 7, tid: 15 },
      { sid: 14, tid: 15 },
      { sid: 13, tid: 11 },
      { sid: 12, tid: 2 },
      { sid: 6, tid: 9 },
    ],
    options: {
      force: 3000,
      nodeSize: 15,
      nodeLabels: true,
      linkWidth: 5,
    },
  }),
};
</script>
  <style src="vue-d3-network/dist/vue-d3-network.css"></style>
  